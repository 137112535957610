<template>
    <div class="modal fade" :id="`brandFormModal${brand.brandid}`" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Brand</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form :id="`form-brand-${brand.brandid}`" @submit.prevent="saveBrand" class="row">
                        <div class="col-12 p-1">
                            <label :for="`form-brand-${brand.brandid}`">Brand Name</label>
                            <input type="text" required v-model.trim="brand.brandname" 
                                :id="`form-brand-${brand.brandid}`" class="form-control">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-xs  btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" :form="`form-brand-${brand.brandid}`" 
                        class="btn btn-xs  btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BrandForm",

    props: {
        brand: {
            type: Object,
            required: true,
        },
    },

    methods: {

        saveBrand() {
            this.$root.loading = true;
            window.axios.post(`brands`, this.brand).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$emit(`refresh`);
                this.$root.closeModal(`brandFormModal${this.brand.brandid}`);
            }).catch(() => { this.$root.loading = false; });
        }
    }
}
</script>
