<template>
<div class="modal fade" :id="`saleRepresentativeFormModal${rep.id}`" 
    data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5" id="staticBackdropLabel">Sale Representative</h5>
                    <button type="button" class="btn-close" d
                        ata-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form :id="`form-sales-rep-${rep.id}`" @submit.prevent="saveSaleRep" class="modal-body">
                    <div class="row">
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-first-name-${rep.id}`" class="form-label">First Name:*</label>
                            <input type="text" v-model.trim="rep.first_name" 
                                :id="`grid-first-name-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-middle-name-${rep.id}`" class="form-label">Last Name:*</label>
                            <input type="text" v-model.trim="rep.last_name" 
                                :id="`grid-middle-name-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-username-${rep.id}`" class="form-label">Username:*</label>
                            <input type="text" v-model.trim="rep.username" 
                                :id="`grid-username-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-email-${rep.id}`" class="form-label">Email:*</label>
                            <input type="text" v-model.trim="rep.email" 
                                :id="`grid-email-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-contact-${rep.id}`" class="form-label">Contact:*</label>
                            <input type="text" v-model.trim="rep.contact_no" 
                                :id="`grid-contact-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-password-${rep.id}`" class="form-label">Password:*</label>
                            <input type="password" v-model.trim="rep.password" 
                                :id="`grid-password-${rep.id}`"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-xs btn-secondary" 
                        data-bs-dismiss="modal">Close</button>
                    <button type="submit" 
                        :form="`form-sales-rep-${rep.id}`" 
                        class="btn btn-xs btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SaleRepresentativeForm",

    props: {
        rep:  {
           type: Object,
           required: true,
        },
    },

    methods: {

        saveSaleRep() {
            this.$root.loading = true;
            window.axios.post(`sale-representatives`, this.rep).then(({ data }) => {
                this.$emit(`refresh`);
                this.$root.closeModal(`saleRepresentativeFormModal${this.rep.id}`);
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>
