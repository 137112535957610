<template>
    <div class="modal fade" :id="`product-view-${product.id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Product Details</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mx-auto" style="width:10rem; height:8rem; overflow:hidden;">
                        <img style="width:10rem; height:auto;" 
                            :src="product.product_img" 
                            :alt="product.name">
                    </div>
                </div>
                <div class="col-12 p-2">
                    <h3 class="h5">
                        Name
                    </h3>
                    <h6 class="h6">
                        {{ product.name }}
                    </h6>
                </div>
                <div class="col-12 p-2">
                    <h3 class="h5">
                        Company 
                    </h3>
                    <h6 class="h6">
                        {{ product?.user?.company_name }}
                    </h6>
                </div>
                <div class="col-md-6 col-12">
                    <h3 class="h5">
                        Qty
                    </h3>
                    <h6 class="h6">
                        {{ product.quantity }}
                    </h6>
                </div>
                <div class="col-md-6 col-12">
                    <h3 class="h5">
                        Price
                    </h3>
                    <h6 class="h6">
                        {{ product.price }}
                    </h6>
                </div>
                <div class="col-12 p-2">
                    <h3 class="h5">
                        Category
                    </h3>
                    <h6 class="h6">
                        {{ product?.category?.name }}
                    </h6>
                </div>
                <div class="col-12 p-2">
                    <h3 class="h5">
                        Description
                    </h3>
                    <p>
                        {{ product.description }}
                    </p>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-xs btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    </template>
    <script>
    export default {
        name: "ProductView",
    
        props: {
            product: {
                type: Object,
            },
        },
    }
    </script>