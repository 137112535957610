<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Disabled Resources</h5>
            <p class="text-muted small">List of Resources.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchResources(`resources?status=0&search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-1">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Topic</th>
                                <th>Company Name</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resource, idx in resources.data">
                                <td>{{ idx+1 }}</td>
                                <td>{{ resource.resource_topic }}</td>
                                <td>{{ resource.company_name }}</td>
                                <td>{{ resource.date }}</td>
                                <td>{{ resource.status }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#resource-form-modal-${resource.id}`"
                                        class="btn btn-xs btn-primary mr-1">Edit</button>
                                    <router-link class="btn btn-xs btn-info"
                                        :to="{ name: `sponsors.resources.view`, 
                                            params: { resourceid: resource.resources_id } }">Details</router-link>
                                        <resource-form 
                                            :resource="resource"
                                        />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in resources.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchResources(`${link.url}&status=0&search=${filter.search}`)" 
                                    v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ResourceForm from './Form.vue'
export default {
    name: "ResourcesDisabledIndex",

    data() {
        return {
            resources: {
                data: []
            },
            filter: {
                search: ``,
            },
        }
    },

    async mounted() {
        this.$root.loading = true;
        this.fetchResources();
        this.$root.loading = false;
    },

    methods: {

        async fetchResources(url=`resources?status=0`) {

           await window.axios.get(url).then(({ data }) => {
                let resources = data.data;
                let lresources = {
                    ...resources,
                    data : data.data.data.map(res => ({
                        ...res,
                        rstatus: res.status == `enabled` ? true : false
                    }))
                };
                this.resources = lresources;
            }).catch(() => {});
        },
    },
    components: {
        'resource-form': ResourceForm
    },
}
</script>