<template>
    <div class="modal fade" :id="`view-sponsor-modal-${sponsor.uid}`" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Sponsor Details</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" 
                                :class="{
                                    'active': tab==0,
                                }"  @click.prevent="tab=0"
                                aria-current="page" href="#">Company Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                                :class="{
                                    'active': tab==1,
                                }" @click.prevent="tab=1"
                                href="#">Resources Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" 
                                :class="{
                                    'active': tab==2,
                                }"  @click.prevent="tab=2"
                                href="#">Product Details</a>
                        </li>
                         <li class="nav-item">
                            <a class="nav-link" 
                                :class="{
                                    'active': tab==3,
                                }"  @click.prevent="tab=3"
                                href="#">Leads</a>
                        </li>
                    </ul>
                    <CompanyDetails 
                        v-if="tab==0"
                        :user="userDetails.user"
                    />
                    <ResourcesTab 
                        v-if="tab==1"
                        :resources="userDetails.resources"
                    />
                    <ProductsTab
                        v-if="tab==2"
                        :products="userDetails.products"
                    />
                    <LeadsTab
                        v-if="tab==3"
                        :leads="userDetails.leads"
                    />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CompanyDetails from './tabs/Company.vue';
import ResourcesTab from './tabs/Resources.vue';
import ProductsTab from './tabs/Products.vue'
import LeadsTab from './tabs/Leads.vue'
export default {
    name: "ViewSponsor",

    data() {

        return {
            tab: 0,
        };
    },

    props: {
        sponsor: {
            type: Object,
            required: true,
        },
        userDetails: {
            type: Object,
            required: true,
            default: () => ({ products: [], resources: [], user: {}, leads: [] }),
        },
    },
    components: {
        CompanyDetails, ResourcesTab, ProductsTab, LeadsTab
    }
}
</script>