<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Sponsor levels</h5>
            <p class="text-muted small">List of sponsor levels.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" 
                        data-bs-toggle="modal" 
                        :data-bs-target="`#sponsor-level-${sponsorLevel.id}`" 
                        class="btn btn-xs btn-primary">Add Sponsor Level</button>
                </div>
                <sponsor-level-form @refresh="() => {
                    sponsorLevel = { id: 0, name: ``, price: `` };
                    fetchSponsorLevels();
                }" :level="sponsorLevel"></sponsor-level-form>
                <div class="col-md-4 col-12">
                    <form class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Number Of Materials/Month</th>
                                <th>AI Enabled</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(level, idx) in sponsorLevels.data">
                                <td>{{ (idx + sponsorLevels.from - 1) + 1 }}</td>
                                <td>{{ level.name }}</td>
                                <td>{{ level.price }}</td>
                                <td>{{ level.ai_enabled ? `Enabled` : `Not Enabled` }}</td>
                                <td>
                                    <button type="button"
                                        data-bs-toggle="modal" 
                                        :data-bs-target="`#sponsor-level-${level.id}`"
                                        class="btn btn-xs btn-primary mr-1">Edit</button>
                                    <button type="button" 
                                        @click.prevent="deleteSponsorLevel(level.id)" 
                                        class="btn btn-xs btn-danger">Delete</button>
                                    <sponsor-level-form 
                                        @refresh="fetchSponsorLevels" 
                                        :level="level">
                                    </sponsor-level-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in sponsorLevels.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchSponsorLevels(link.url)"
                                    v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SponsorLevelForm from './Form.vue';
export default {
    name: "SponsorLevelsIndex",

    data() {

        return {
            sponsorLevels: {
                data: [],
                links: [],
                current_page: 1
            },
            sponsorLevel: {
                id: 0,
                name: ``,
                price: ``,
                ai_enabled: 0,
            },
            filter: {
                search: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchSponsorLevels();
        this.$root.loading = false;
        console.log(``);
    },

    methods: {

        fetchSponsorLevels(url=`sponsor-levels`) {

            return window.axios.get(url).then(({ data }) => {
                this.sponsorLevels = data.data;
            }).catch(() => {});
        },

        deleteSponsorLevel(id) {
            if(!window.confirm(`Delete level?`)) return;
    
            this.$root.loading = true;
            window.axios.delete(`sponsor-levels/${id}`).then(({ data }) => {
                this.fetchSponsorLevels();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        }
    },

    components: {
        'sponsor-level-form': SponsorLevelForm,
    },
}
</script>