<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in products">
                            <td>{{ product.name }}</td>
                            <td>{{ product.category?.name}}</td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.price }}</td>
                            <td>
                                <a :href="`/products/${product.product_id}/view`" 
                                    class="btn btn-xs btn-primary">
                                    Edit
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductsTab",

    props: {

        products: {
            type: Array,
            required: true,
            default: () => ([]),
        },
    },
}
</script>