<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Resources</h5>
            <p class="text-muted small">List of Resources.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="table-responsive mt-1">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Topic</th>
                                <th>Company Name</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="resource, idx in topics.data">
                                <td>{{ idx+1 }}</td>
                                <td>{{ resource.resource_topic }}</td>
                                <td>{{ resource.company_name }}</td>
                                <td>{{ resource.date }}</td>
                                <td>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            v-model="resource.rstatus"
                                            :value="true"
                                            id="flexSwitchCheckChecked">
                                    </div>
                                </td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#resource-form-modal-`"
                                        class="btn btn-xs btn-primary">Edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in topics.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchResources(link.url)" v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ViewResources",

    data() {

        return {
            topics: {
                data: [],
                links: [],
            },
        };
    },

    async mounted() {
        await this.fetchResourceTopics();
    },

    methods: {

        fetchResourceTopics() {

            return window.axios.get(`resources-topics/${this.$route.params.slug}`).then(({ data }) => {
                this.topics = data.data;
            }).catch(() => {});
        },
    },
}
</script>