import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"
import AdminLayout from './layouts/Admin';
import HomePage from './pages/Index.vue';
import AdministratorsIndex from './pages/administrators/Index.vue';

import BrandsIndex from './pages/brands/index.vue';
import IndustriesIndex from './pages/industries/index.vue';
import ProductCategoriesIndex from './pages/product-categories/Index.vue';
import ProductSubCategoriesIndex from './pages/subcategories/Index.vue';
import SaleRepresentativeIndex from './pages/sale-representatives/index.vue';

import SubscribersIndex from './pages/subscribers/Index.vue';

import ScriptsIndex from './pages/scripts/Index.vue';
import ScriptsForm from './pages/scripts/Form.vue';

import AdsIndex from './pages/ads/Index.vue';
import AdForm from './pages/ads/Form.vue';

import NewslettersIndex from './pages/newsletters/Index.vue';
import NewslettersForm from './pages/newsletters/Form.vue';

import SponsorLevelIndex from './pages/sponsor-levels/Index.vue';

import ProductsIndex from './pages/products/Index.vue';
import ProductsForm from './pages/products/Form.vue';

import { routes as resources } from './pages/resources/routes';
import { routes as auth } from './pages/auth/routes';
import  { routes as sponsors } from './pages/sponsors/routes'

const routes = [
    {
        path: '/',
        component: AdminLayout,
        children: [
            {
                path: '',
                component: HomePage,
                name: 'home',
            },
            {
                path: 'administrators',
                component: AdministratorsIndex,
                name: 'administrators.index',
            },
            
            {
                path: 'brands',
                component: BrandsIndex,
                name: 'brands.index',
            },
            {
                path: 'industries',
                component: IndustriesIndex,
                name: 'industries.index',
            },
            {
                path: 'product-categories',
                component: ProductCategoriesIndex,
                name: 'product-categories.index',
            },
            {
                path: 'product-subcategories',
                component: ProductSubCategoriesIndex,
                name: 'product-subcategories.index',
            },
            {
                path: 'sale-representatives',
                component: SaleRepresentativeIndex,
                name: 'sale-representatives.index',
            },
            ...sponsors,
            {
                path: 'subscribers',
                component: SubscribersIndex,
                name: 'subscribers.index',
            },
            ...resources,
            // scripts
            {
                path: 'scripts',
                component: ScriptsIndex,
                name: 'scripts.index',
            },
            {
                path: 'scripts/form',
                component: ScriptsForm,
                name: 'scripts.form',
            },
            {
                path: 'scripts/form/:id',
                component: ScriptsForm,
                name: 'scripts.edit',
            },
            // ad configuration
            {
                path: 'ads',
                component: AdsIndex,
                name: 'ads.index',
            },
            {
                path: 'ads/form',
                component: AdForm,
                name: 'ads.form',
            },
            {
                path: 'ads/form/:id',
                component: AdForm,
                name: 'ads.edit',
            },
            {
                path: 'newsletters',
                component: NewslettersIndex,
                name: 'newsletters.index',
            },
            {
                path: 'newsletters/form',
                component: NewslettersForm,
                name: 'newsletters.form',
            },
            {
                path: 'newsletters/:id/edit',
                component: NewslettersForm,
                name: 'newsletters.edit',
            },
            {
                path: 'sponsor-levels',
                component: SponsorLevelIndex,
                name: 'sponsor-levels.index',
            },
            {
                path: 'products',
                component: ProductsIndex,
                name: 'products.index',
            },
            {
                path: 'products/:productid/view',
                component: ProductsForm,
                name: 'products.form',
            },
        ],
    },

    { path: '/', redirect: { name: 'home' } },
    ...auth,

]

export const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.afterEach((to, from) => {
    let btn = document.getElementById(`sidebarMenuTogglerBtn`);
    // window.alert(window.screen.width)
    
    if(btn && (window.screen.height < 768 || window.screen.width < 768) && to.query.sidebar) {
        btn.click();
        // window.alert(window.screen.width)
    }
  });
