<template>
    <div class="card mt-3">
        <div class="card-header">
            <h1 class="h5">Add newsletter</h1>
        </div>
        <div class="card-body">
            <form @submit.prevent="saveNewsletter" id="form-newsletter" class="row">
                <div class="col-md-4 col-12 p-1">
                    <label for="form-subject" class="form-label">Subject</label>
                    <input type="text" v-model.trim="newsletter.subject" required id="form-subject" class="form-control">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-date" class="form-label">Date</label>
                    <input type="date" v-model.trim="newsletter.scheduled_time" required id="form-date" class="form-control">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="" class="form-label">Accounts Group</label>
                    <v-select multiple v-model="newsletter.groups"  :reduce="newsltter => newsltter.type" :options="[ { type: `Current` }, { type: `Previous` }, { type: `About to expire` }]" label="type"></v-select>
                </div>
                <div class="col-12 mt-1 p-2">
                    <label class="form-label">Newsletter</label>
                    <QuillEditor v-model:content="newsletter.newsletter" contentType="html" theme="snow" />
                </div>
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="submit" form="form-newsletter" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: "NewslettersForm",

    data() {

        return {
            newsletter: {
                subject: ``,
                scheduled_time: ``,
                groups: [],
                newsletter: ``,
            }
        }
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchNewsletter();
        this.$root.loading = false;
    },

    methods: {

        fetchNewsletter() {

            return window.axios.get(`newsletters/${this.$route.params.id}`).then(({ data }) => {
                this.newsletter = data.data;
            }).catch(() => {});
        },

        saveNewsletter() {
            this.$root.loading = true;
            window.axios.post(`newsletters`, this.newsletter).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$router.push({ name: `newsletters.index`});
             }).catch(() => { this.$root.loading = false; });
        },
    },

    components: {
        QuillEditor,
    },
}
</script>
<style>
.ql-container {
    height: auto !important;
}
</style>