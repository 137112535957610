<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Categories</h5>
            <p class="text-muted small">List of categories.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" data-bs-toggle="modal"
                        :data-bs-target="`#categoryFormModal${addCategory.category_id}`" class="btn btn-xs btn-primary">Add
                        Category</button>
                </div>
                <product-category-form :category="addCategory" @refresh="fetchCategories"></product-category-form>
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchCategories(`product-categories?paginate=1&search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category, idx in categories.data">
                                <td>{{ idx + 1 }}</td>
                                <td>{{ category.name }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#categoryFormModal${category.category_id}`"
                                        class="btn btn-xs btn-primary">Edit</button>
                                    <product-category-form :category="category"
                                        @refresh="fetchCategories"></product-category-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in categories.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchCategories(`${link.url}&paginate=1`)"
                                    v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductCategoryForm from './Form.vue'
export default {
    name: "ProductCategoriesIndex",

    data() {

        return {
            categories: {
                data: [],
                links: []
            },
            addCategory: {
                category_id: 0,
                name: ``,
            },
            filter: {
                search: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchCategories();
        this.$root.loading = false;
    },

    methods: {

        fetchCategories(url = `product-categories?paginate=1`) {

            return window.axios.get(url).then(({ data }) => {
                this.categories = data.data;
            }).catch(() => { });
        },
    },

    components: {
        'product-category-form': ProductCategoryForm,
    },
}
</script>