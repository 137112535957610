<template>
    <div class="card mt-3">
            <div class="card-header">
                <h5 class="h5">Ad</h5>
                <p class="text-muted fs-5">{{ ad.position }}</p>
            </div>
            <div class="card-body">
                <form @submit.prevent="saveAd" id="ad-form" class="row">
                    <div class="col-12 p-1">
                        <label for="google-ad-script-to-embed" class="form-label">Ad Script</label>
                        <textarea id="google-ad-script-to-embed" 
                            v-model="ad.google_ad" required
                            cols="30" rows="3" class="form-control"></textarea>
                    </div>
                    <div class="col-12 p-1">
                        <label for="grid-ad-script-to-embed" class="form-label">Widget to embed</label>
                        <textarea id="grid-ad-script-to-embed" 
                            v-model="ad.ad_script" required
                            cols="30" rows="3" class="form-control"></textarea> 
                    </div>
                    <div class="col-12 p-1">
                        <label for="grid-active" class="form-label">Status</label>
                        <select id="grid-active" v-model="ad.active" class="form-select">
                            <option :value="1">Active</option>
                            <option :value="0">Inactive</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 text-end">
                        <router-link :to="{ name: `ads.index` }" 
                            class="btn btn-link btn-sm mr-4">Back</router-link>
                        <button form="ad-form" type="submit" 
                            class="btn btn-sm btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "AdForm",

    data() {

        return {
            ad: {
                position: ``,
                active: 1,
                link: ``,
                path: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchAd();
        this.$root.loading = false;
    },

    methods: {

        saveAd() {
            this.$root.loading = true;
            window.axios.post(`banners`, this.ad).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$router.push({ name: `ads.index` });
            }).catch(() => { this.$root.loading = false;});
        },

        fetchAd() {
            if(!this.$route.params.id) return;
    
            return window.axios.get(`banners/view/${this.$route.params.id}`).then(({ data}) => {
                this.ad = data.data;
            }).catch(() => {});
        },
    }
}
</script>