<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Products</h5>
            <p class="text-muted small">List of products.</p>
        </div>
        <div class="card-body">
            <form @submit.prevent="fetchProducts(`products?search=${filter.search}`)" class="row mb-1">
                <div class="col-md-4 col-12">
                    <input type="text" 
                        v-model.trim="filter.search"
                        class="form-control">
                </div>
                <div class="col-md-3 col-12">
                    <button type="submit" class="btn btn-sm btn-primary mt-1">Search</button>
                </div>
                <div class="col-md-5 col-12 text-end">
                    <router-link class="btn btn-link" 
                        :to="{ name: `sponsors.products.form`, 
                                params: { slug: $route.params.slug } }">Add Product</router-link>
                </div>
            </form>
            <div class="table-reponsive">
                <table class="table table-bordered">
                    <thead class="bg-secondary">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Company</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product, idx in products.data">
                            <td>{{ (products.current_page * idx) + 1 }}</td>
                            <td>{{ product.name }}</td>
                            <td>{{ product?.category?.name }}</td>
                            <td>{{ product?.user?.company_name }}</td>
                            <td>{{ product.quantity }}</td>
                            <td>{{ product.price }}</td>
                            <td>
                                <button type="button" data-bs-toggle="modal"
                                    :data-bs-target="`#product-view-${product.id}`" class="btn btn-xs btn-info mr-1">
                                    View
                                </button>
                                <router-link :to="{
                                    name: `sponsors.products.edit`,
                                    params: { productid: product.product_id, slug: $route.params.slug }
                                    }" class="btn btn-xs btn-primary mr-1">
                                    Edit
                                </router-link>
                                <button @click.prevent="deleteProduct(product.product_id)"
                                    class="btn btn-xs btn-danger">
                                    Delete
                                </button>
                                <product-view :product="product" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation">
                    <ul class="pagination">
                        <li class="page-item" v-for="link in products.links">
                            <button class="page-link" :class="{
                            'disabled': link.url ? false : true,
                            'bg-primary text-white': link.active
                        }" @click.prevent="fetchProducts(`${link.url}&search=${filter.search}`)" v-html="link.label"></button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
import ProductView from "./View.vue";
export default {
    name: "ProductsIndex",

    data() {
        return {
            products: {
                links: [],
                data: [],
            },
            filter: {
                search: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchProducts();
        this.$root.loading = false;
    },

    methods: {

        fetchProducts(url = `products/${this.$route.params.slug}/sponsor?search=${this.filter.search}`) {

            return window.axios.get(url).then(({ data }) => {
                this.products = data.data;
            }).catch(() => { });
        },

        deleteProduct(slug) {
            if (!window.confirm(`Delete product?`)) return;

            window.axios.delete(`products/${slug}/delete`).then(({ data }) => {
                this.$root.showNotification({ status: 1, message: data.message });
                this.fetchProducts();
            }).catch(() => { });
        },
    },
    components: {
        'product-view': ProductView
    },
}
</script>