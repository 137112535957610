<template>
    <div class="modal fade" :id="`industryFormModal${industry.industryid}`" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Industry</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form :id="`form-industry-${industry.industryid}`" @submit.prevent="saveBrand" class="row">
                        <div class="col-12 p-1">
                            <label :for="`form-industry-${industry.industryid}`">Industry Name</label>
                            <input type="text" required v-model.trim="industry.industryname" 
                                :id="`form-industry-${industry.industryid}`" class="form-control">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-xs  btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" :form="`form-industry-${industry.industryid}`" 
                        class="btn btn-xs  btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "IndustryForm",

    props: {
        industry: {
            type: Object,
            required: true,
        },
    },

    methods: {

        saveBrand() {
            this.$root.loading = true;
            window.axios.post(`industries`, this.industry).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$emit(`refresh`);
                this.$root.closeModal(`industryFormModal${this.industry.industryid}`);
            }).catch(() => { this.$root.loading = false; });
        }
    }
}
</script>
