<template>
        <div class="col-lg-4 col-md-6 col-10 mx-auto m-auto mt-4">
            <div class="card">
                <div class="card-body">
                    <main class="m-auto">
                        <form @submit.prevent="loginUser">
                            <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
                            <div class="form-floating">
                                <input type="email" 
                                    v-model.trim="login.email"
                                    class="form-control" id="floatingInput" required placeholder="name@example.com">
                                <label for="floatingInput">Email address</label>
                            </div>
                            <div class="form-floating mt-3 mb-3">
                                <input type="password" 
                                    v-model="login.password"
                                    class="form-control" id="floatingPassword" required placeholder="Password">
                                <label for="floatingPassword">Password</label>
                            </div>

                            <button class="btn btn-primary w-100 py-2" type="submit">Sign in</button>
                            <p class="mt-5 mb-3 text-body-secondary">&copy; Resource Mining Center</p>
                        </form>
                    </main>
                </div>
            </div>
        </div>
</template>
<script>
import jsCookie from 'js-cookie';
export default {
    name: "LoginIndex",

    data() {

        return {
            login: {
                email: ``,
                password: ``,
            },
        };
    },

    methods: {

        loginUser() {
            this.$root.loading = true;
            window.axios.post(`auth/login`, this.login).then(({ data }) => {

                jsCookie.set('__LOGIN__', data.data);
                this.$root.loading = false;
                window.location.replace('/');
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>