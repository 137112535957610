<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Sale representatives</h5>
            <p class="text-muted small">List of Sale representatives.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" data-bs-toggle="modal"
                        :data-bs-target="`#saleRepresentativeFormModal${addRep.id}`" class="btn btn-xs btn-primary">Add
                        Sale Representative</button>
                </div>
                <sale-representative-form
                    @refresh="fetchReps"
                    :rep="addRep"
                ></sale-representative-form>
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchReps(`sale-representatives?search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-1">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Contact No</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rep, idx in representatives.data">
                                <td>{{ idx + 1 }}</td>
                                <td>{{ `${rep.last_name} ${rep.last_name}` }}</td>
                                <td>{{ rep.username }}</td>
                                <td>{{ rep.email }}</td>
                                <td>{{ rep.contact_no }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#saleRepresentativeFormModal${rep.id}`"
                                        class="btn btn-xs btn-primary mr-1">Edit</button>
                                    <button type="button" @click.prevent="deleteSalesRep(rep.id)" 
                                        class="btn btn-xs btn-danger">Delete</button>
                                        <sale-representative-form
                                            :rep="rep"
                                        ></sale-representative-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in representatives.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchReps(`${link.url}&search=${filter.search}`)" 
                                    v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SaleRepresentativeForm from './form.vue';
export default {
    name: "SaleRepresentativeIndex",

    data() {

        return {
            representatives: {
                data: [],
                links: [],
            },
            addRep: {
                id: 0,
                first_name: ``,
                last_name: ``,
                username: ``,
                email: ``,
                contact_no: ``,
                password: ``,
            },
            filter: {
                search: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchReps();
        this.$root.loading = false;
    },

    methods: {

        fetchReps(url=`sale-representatives`) {

           return window.axios.get(url).then(({ data }) => {
            this.representatives = data.data;
            }).catch(() => {});
        },

        deleteSalesRep(id) {
            if(!window.confirm(`Delete Sales rep?`)) return;
            this.$root.loading = true;
            window.axios.delete(`sale-representatives/${id}`).then(({ data }) => {
                this.fetchReps();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },
    },

    components: {
        "sale-representative-form": SaleRepresentativeForm,
    },
}
</script>