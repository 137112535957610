<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Industries</h5>
            <p class="text-muted small">List of Industries.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" data-bs-toggle="modal"
                        :data-bs-target="`#industryFormModal${addIndustry.industryid}`" class="btn btn-xs btn-primary">Add
                        Industry</button>
                </div>
                <industry-form :industry="addIndustry" @refresh="fetchIndustries"></industry-form>
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchIndustries(`industries?search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="industry, idx in industries.data">
                                <td>{{ idx + 1 }}</td>
                                <td>{{ industry.industryname }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#industryFormModal${industry.industryid}`"
                                        class="btn btn-xs btn-primary">Edit</button>
                                    <industry-form :industry="industry" @refresh="fetchIndustries"></industry-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in industries.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchIndustries(link.url)" v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IndustryForm from './form.vue';
export default {
    name: "BrandsIndex",

    data() {

        return {
            industries: {
                data: []
            },
            addIndustry: {
                industryid: 0,
                industryname: ``,
            },
            filter: {
                search: ``,
                limit: 20,
            }
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchIndustries();
        this.$root.loading = false;
    },

    methods: {

        fetchIndustries(url = `industries`) {

            return window.axios.get(url).then(({ data }) => {
                this.industries = data.data;
            }).catch(() => { });
        }
    },

    components: {
        'industry-form': IndustryForm,
    },
}
</script>