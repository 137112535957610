<template>
  <div class="modal fade" :id="`sponsor-level-${level.id}`" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Sponsor Level</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form :id="`form-sponsor-level-${level.id}`" @submit.prevent="saveSponsorLevel" class="row">
            <div class="col-12 p-1 mb-1">
              <label :for="`form-name-${level.id}`">Name</label>
              <input type="text" required :id="`form-name-${level.id}`" v-model.trim="level.name"
                class="form-control form-control-sm">
            </div>
            <div class="col-12 p-1 mb-1">
              <label :for="`form-price-${level.id}`">No Materials/Month</label>
              <input type="number" required :id="`form-price-${level.id}`" 
                v-model.number="level.price"
                class="form-control form-control-sm">
            </div>
            <div class="col-12 p-1 mb-1">
              <label :for="`form-ai-enabled-${level.id}`">AI Enabled</label>
              <select v-model="level.ai_enabled" :id="`form-ai-enabled-${level.id}`" class="form-select form-select-sm">
                <option :value="0">Not Enabled</option>
                <option :value="1">Enabled</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-xs btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="submit" :form="`form-sponsor-level-${level.id}`" class="btn btn-xs btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SponsorLevelForm",

  props: {
    level: {
      type: Object,
      required: true,
    },
  },

  methods: {

    saveSponsorLevel() {
      this.$root.loading = true;
      window.axios.post(`sponsor-levels`, this.level).then(({ data }) => {

        this.$emit(`refresh`);
        this.$root.closeModal(`sponsor-level-${this.level.id}`);
        this.$root.loading = false;
        this.$root.showNotification({ status: 1, message: data.message });
      }).catch(() => { this.$root.loading = false; });
    },
  }
}
</script>
