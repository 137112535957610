import ResourcesIndex from './Index.vue';
import ResourcesEnabledIndex from './Enabled.vue';
import ResourcesDisabledIndex from './Disabled.vue';
import ResourcesView from './View.vue';

export const routes = [
    {
        path: 'all-resources',
        component: ResourcesIndex,
        name: 'resources.index',
    },
    {
        path: 'enabled-resources',
        component: ResourcesEnabledIndex,
        name: 'resources.enabled.index',
    },
    {
        path: 'disabled-resources',
        component: ResourcesDisabledIndex,
        name: 'resources.disabled.index',
    },
    {
        path: 'resources/:slug',
        component: ResourcesView,
        name: 'resources.view',
    },
]