<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Sponsor Resources</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <router-link class="btn btn-info btn-sm" :to="{ name: `sponsors.resources.form`, params: { slug: $route.params.slug } }">
                        Add Resource
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchResources(`resources/sponsors/${this.$route.params.slug}?search=${filter.search}`).then(() => {
                            $root.loading = false;
                        })
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-1">
                    <div class="row mb-3">
                        <div v-for="resource in resources.data" class="col-md-6 col-12 p-2">
                            <div class="card">
                                <div class="card-body">
                                    <div class="col-12 mx-auto overflow-hidden" style="width:300px; height:200px;">
                                        <img :src="resource.image_path" style="width: 300px; height:auto;" alt="">
                                    </div>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{{ resource.resource_topic }}</td>
                                            </tr>
                                            <tr>
                                                <td>Category</td>
                                                <td>{{ resource.category ? resource.category.industryname : `-` }}</td>
                                            </tr>
                                            <tr>
                                                <td>Company Name</td>
                                                <td>{{ resource.company_name }}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>{{ resource.company_name }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-12 text-end">
                                            <router-link 
                                                :to="{ 
                                                        name: `sponsors.resources.edit`, 
                                                        params: { 
                                                            resourceId: resource.resources_id,
                                                            slug: $route.params.slug,
                                                        } 
                                                    }" 
                                                class="btn btn-xs btn-info mr-4">Edit</router-link>
                                            <router-link 
                                                :to="{ name: `sponsors.resources.view`, params: { resourceid: resource.resources_id } }" 
                                                class="btn btn-xs btn-primary mr-4">View</router-link>
                                            <button type="button" 
                                                @click.prevent="deleteResource(resource.id)"
                                                class="btn btn-xs btn-danger">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in resources.links">
                                <button class="page-link" :class="{
                        'disabled': link.url ? false : true,
                        'bg-primary text-white': link.active
                    }" @click.prevent="fetchResources(`${link.url}&search=${filter.search}&package=p2`)"
                                    v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SponsorResourcesIndex",

    data() {

        return {
            resources: {
                data: [],
                links: [],
            },
            filter: {
                search: ``,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchResources();
        this.$root.loading = false;
    },

    methods: {
        fetchResources(url = `resources/sponsors/${this.$route.params.slug}`) {

            return window.axios.get(url).then(({ data }) => {
                this.resources = data.data;
            }).catch(() => { });
        },

        deleteResource(id) {
            if(!window.confirm(`Confirm delete resource`)) return;

            this.$root.loading = true;
            window.axios.delete(`resources/sponsors/${id}`).then(({ data }) => {
                this.$root.showNotification({ status: 1, message: data.message });
                this.fetchResources();
                this.$root.loading = false;
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>