<template>
    <div class="card mt-3">
            <div class="card-header">
                <h5 class="h5">Script</h5>
                <p class="text-muted small">Script </p>
            </div>
            <div class="card-body">
                <form id="script-form" @submit.prevent="saveScript" class="row">
                    <div class="col-12 p-1">
                        <label for="grid-label" class="form-label">Label</label>
                        <input required type="text" v-model.trim="script.label" 
                            id="grid-label" class="form-control">
                    </div>
                    <div class="col-12 p-1">
                        <label for="grid-description" class="form-label">Script</label>
                        <textarea required id="grid-description"  v-model.trim="script.script"
                            cols="30" rows="5" class="form-control"></textarea>
                    </div>
                    <div class="col-12 p-1">
                        <label for="grid-active" class="form-label">Status</label>
                        <select v-model="script.active" id="grid-active" class="form-select">
                            <option :value="1">Active</option>
                            <option :value="0">Inactive</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 text-end">
                        <router-link :to="{ name: `scripts.index` }" 
                            class="btn btn-link btn-sm mr-4">Back</router-link>
                        <button form="script-form" type="submit" 
                            class="btn btn-sm btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "ScriptForm",

    data() {

        return {
            script: {
                label: ``,
                script: ``,
                active: 1,
            },
        };
    },

    async mounted() {

        await this.fetchScript();
    },

    methods: {

        saveScript() {
            this.$root.loading = true;
            window.axios.post(`scripts`, this.script).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false;});
        },

        fetchScript() {
            if(!this.$route.params.id) return;
    
            return window.axios.get(`scripts/${this.$route.params.id}`).then(({ data}) => {
                this.script = data.data;
            }).catch(() => {});
        },
    }
}
</script>