import jsCookie from 'js-cookie';
const axios = require("axios");
import { router } from './router';

export const instance = axios.create({
  baseURL: "/api/v1",
  headers: {
    Authorization: `Bearer ${jsCookie.get('__LOGIN__')}`
  }
});

instance.interceptors.request.use(
  function(config) {

    return config;
  },
  function(error) {

    return Promise.reject(error);
});

instance.interceptors.response.use(
  function (response) {
    return response;
},
  function(error) {

    if(error.response.status == 400) {
      router.push({name: `login.index`});
    }
    const errors = Object.values(error.response.data);

    return Promise.reject(errors);
  }
);

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
