<template>
    <div class="card mt-3">
            <div class="card-header">
                <h5 class="h5">Script</h5>
                <p class="text-muted small">List of Scripts</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-end">
                        <router-link :to="{ name: `scripts.form` }"
                            class="btn btn-sm btn-link">Add Script</router-link>
                    </div>
                    <div class="table-responsive mt-1">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Label</th>
                                    <th>Active</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="script, idx in scripts">
                                    <td>{{ idx+1 }}</td>
                                    <td>{{ script.label }}</td>
                                    <td>{{ script.active ? `Active` : `NA`}}</td>
                                    <td>
                                        <router-link class="btn btn-link" :to="{ 
                                                name: `scripts.edit`, params: { id: script.id, } }">Edit</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "ScriptIndex",

    data() {

        return {
            scripts: [],
        }
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchScripts();
        this.$root.loading = false;
    },

    methods: {

        fetchScripts(url=`scripts`) {

            return window.axios.get(url).then(({ data }) => {
                console.log(data);
                this.scripts = data.data;
            }).catch(() => {});
        },
    }
}
</script>