<template>
    <div class="row">
        <div class="col-12">
            <!-- Filters in One Line -->
            <div class="d-flex gap-2 align-items-center mb-3 mt-3">
                <label>Start Date:</label>
                <input type="date" v-model="startDate" class="form-control w-auto" />
                
                <label>End Date:</label>
                <input type="date" v-model="endDate" class="form-control w-auto" />
                
                <button class="btn btn-primary" @click="filterLeads">Filter</button>
                <button class="btn btn-secondary" @click="resetFilter">Reset</button>
                <button class="btn btn-info" @click="downloadCSV">Download CSV</button>
            </div>

            <!-- Leads Table -->
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Company Name</th>
                            <th>Email</th>
                            <th>Cell</th>
                            <th>Work</th>
                            <th>Address</th>
                            <th>Region</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="lead in filteredLeads" :key="lead.id">
                            <td>{{ lead.resource?.resource_topic || '' }}</td>
                            <td>{{ lead.company_name }}</td>
                            <td>{{ lead.email }}</td>
                            <td>{{ lead.download_user?.cell }}</td>
                            <td>{{ lead.download_user?.work }}</td>
                            <td>{{ lead.download_user?.address }}</td>
                            <td>{{ lead.region }}</td>
                            <td>{{ formatDate(lead.timestamp) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeadsTab",
    props: {
        leads: {
            type: Array,
            required: true,
            default: () => ([]),
        },
    },
    data() {
        return {
            startDate: "",
            endDate: "",
            filteredLeads: this.leads, 
        };
    },
    methods: {
    formatDate(value) {
    if (!value) return ''; // Handle empty dates

    let date;

    // Check if value is a Unix timestamp (all digits, no slashes)
    if (/^\d+$/.test(value)) {
        date = new Date(value * 1000); // Convert seconds to milliseconds
    } else {
        date = new Date(value); // Parse standard date string
    }

    if (isNaN(date.getTime())) return "Invalid Date"; // Fallback for invalid dates

    // Format date with spaces instead of commas
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',  // "Jan", "Feb"
        day: '2-digit',  // "01", "02"
        hour12: false,  // Use 24-hour format
    }).format(date).replace(',', ' '); // Replacing comma with space
},
        filterLeads() {
            if (!this.startDate || !this.endDate) {
                alert("Please select both start and end dates!");
                return;
            }

            const start = new Date(this.startDate);
            const end = new Date(this.endDate);

            this.filteredLeads = this.leads.filter(lead => {
                const leadDate = new Date(lead.timestamp);
                return leadDate >= start && leadDate <= end;
            });
        },
        resetFilter() {
            this.startDate = "";
            this.endDate = "";
            this.filteredLeads = this.leads;
        },
        downloadCSV() {
            if (!this.filteredLeads.length) {
                alert("No data available for download!");
                return;
            }

            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += "Resource,Company Name,Email,Cell,Work,Address,Region,Date\n";

            this.filteredLeads.forEach(lead => {
                let row = [
                    lead.resource?.resource_topic || '',
                    lead.company_name || '',
                    lead.email || '',
                    lead.download_user?.cell || '',
                    lead.download_user?.work || '',
                    lead.download_user?.address || '',
                    lead.region || '',
                    this.formatDate(lead.timestamp)  || ''
                ].join(",");
                csvContent += row + "\n";
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "leads.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>

<style scoped>
/* Adjust width for better alignment */
.w-auto {
    min-width: 150px;
}
</style>
