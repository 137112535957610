<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">{{ $route.params.resourceId ? `Edit` : `Add`}} Sponsor Resources</h5>
        </div>
        <div class="card-body">
            
            <form id="form-resource" @submit.prevent="saveResource" class="row">
                <div v-if="$route.params.resourceId" class="col-md-5 col-11 mx-auto overflow-hidden p-1" style="height:200px; width:300px;">
                    <img :src="resource.image_path" style="width:300px; height:auto;" alt="">
                </div>
                <div class="col-12"></div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-topic" class="form-label">Topic</label>
                    <input type="text" v-model.trim="resource.resource_topic" id="form-topic"
                       required class="form-control form-control-sm">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-topic" class="form-label">Category</label>
                    <v-select v-model="resource.content_category" placeholder="select category" label="industryname"
                        :reduce="topic => topic.industryid" :options="topics"></v-select>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-brand" class="form-label">Brand</label>
                    <v-select v-model="resource.brand_id" placeholder="select brand" label="brandname"
                        :reduce="brand => brand.brandid" :options="brands"></v-select>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-author-name" class="form-label">Author</label>
                    <input type="text" v-model.trim="resource.author_name" id="form-author-name" class="form-control form-control-sm">
                </div>
                <div class="col-12 p-1">
                    <label for="form-keywords" class="form-label">Keywords</label>
                    <v-select v-model="resource.keywords" placeholder="keywords" label="industryname"
                     :taggable="true" :multiple="true" :reduce="keyword => keyword.industryname" :options="keywords"></v-select>
                </div>
                <div class="col-12 mt-1">
                    <label for="" class="form-label">Description</label>
                    <textarea class="form-control" v-model.trim="resource.description" cols="30" rows="4"></textarea>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-image" class="form-label">Image</label>
                    <input type="file" ref="coverImage" :required="!this.$route.params.resourceId" id="form-image" class="form-control form-control-sm">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-content-type" class="form-label">Content Type</label>
                    <select v-model="resource.type_of_resource" required id="form-content-type" class="form-select form-select-sm">
                        <option :value="0">select type</option>
                        <option v-for="type in resourceTypes" :value="type.resourcetypeid">{{ type.resourcetypename }}
                        </option>
                    </select>
                </div>
                <div v-if="resource.type_of_resource !== 6" class="col-md-4 col-12 p-1">
                    <label for="form-resource" class="form-label">Resource(File)</label>
                    <input type="file" ref="resource_file" id="form-resource" class="form-control form-control-sm">
                </div>
                <div v-else class="col-md-4 col-12 p-1">
                    <label for="form-resource" class="form-label">Resource (Link)</label>
                    <input type="text" v-model.trim="resource.resource_path" id="form-resource" class="form-control form-control-sm">
                </div>
                <div class="col-12 text-center">
                    <a target="_blank" :href="resource.resource_path">{{ resource.resource_path }}</a>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 text-end">
                    <router-link :to="{ 
                            name: `sponsors.resources.index`, 
                            params: { resourceid: $route.params.slug } 
                        }" class="btn btn-sm btn-secondary mr-4">Back</router-link>
                    <button type="submit" form="form-resource" class="btn btn-sm btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SponsorResourceForm",

    data() {

        return {
            resource: {
                keywords: ``,
                resource_topic: ``,
                type_of_resource: 0,
                content_category: ``,
                description: ``,
                content_type: ``,
                author_name: ``,
                brand_id: ``,
            },
            topics: [],
            resourceTypes: [],
            brands: [],
            keywords: [],
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchTopics();
        await this.fetchResourceTypes();
        await this.fetchBrands();
        await this.fetchKeywords();
        this.fetchResource();
        this.$root.loading = false;
    },

    methods: {

        fetchTopics() {

            return window.axios.get(`shared/topics`).then(({ data }) => {
                this.topics = data.data;
            }).catch(() => { });
        },

        fetchBrands() {

            return window.axios.get(`shared/brands`).then(({ data }) => {
                this.brands = data.data;
            }).catch(() => { });
        },

        fetchResourceTypes() {

            return window.axios.get(`shared/resource-types`).then(({ data }) => {
                this.resourceTypes = data.data;
            }).catch(() => { });
        },

        fetchKeywords() {

            return window.axios.get(`shared/keywords`).then(({ data }) => {
                this.keywords = data.data;
            }).catch(() => { });
        },

        fetchResource() {
            if(!this.$route.params.resourceId) return;

            window.axios.get(`resources/${this.$route.params.resourceId}`).then(({ data }) => {
                this.resource = { ...data, keywords: data.keywords.split(',')};
            }).catch(() => {});
        },

        saveResource() {
            if(!this.resource.content_category) {
                window.alert(`Category required`);
                return;
            }
            if(!this.resource.type_of_resource) {
                window.alert(`Type of resource required`);
                return;
            }
            if(!this.resource.brand_id) {
                window.alert(`Brand is required`);
                return;
            }
            const formData = new FormData();
            formData.append('keywords', this.resource.keywords);
            formData.append('resource_topic', this.resource.resource_topic);
            formData.append('type_of_resource', this.resource.type_of_resource);
            formData.append('content_category', this.resource.content_category);
            formData.append('description', this.resource.description);
            formData.append('content_type', this.resource.content_type);
            formData.append('brand_id', this.resource.brand_id);
            formData.append('author_name', this.resource.author_name);
            if(this.resource.id) {
                formData.append('id', this.resource.id);
            }
            if(this.$refs.resource_file.files.length) {
                formData.append('file', this.$refs.resource_file.files[0]);
            }
            if(this.$refs.coverImage.files.length) {
                formData.append('cover_image', this.$refs.coverImage.files[0]);
            }

            this.$root.loading = true;
            window.axios.post(`resources/sponsors/${this.$route.params.slug}`, formData).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                setTimeout(() => {
                    this.$router.back();
                }, 300);
                
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>