<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Ads</h5>
            <p class="text-muted small">List of Ads</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <!-- <router-link :to="{ name: `ads.form` }" class="btn btn-sm btn-link">Ad Add</router-link> -->
                </div>
                <div v-for="ad in ads" class="col-md-6 col-12 p-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-body">
                                <h3 class="h4">Position: {{ ad.position }}</h3>
                                <h6 class="h4">Active: {{ ad.active ? `Active` : `Inactive` }}</h6>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-12 text-end">
                                        <router-link :to="{ name: `ads.edit`, params: { id: ad.id } }">Edit</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AdsIndex",

    data() {

        return {
            ads: [],
        }
    },

    async mounted() {
        this.$root.loading = true;
        this.fetchAds();
        this.$root.loading = false;
    },

    methods: {

        fetchAds() {

            return window.axios.get(`banners`).then(({ data }) => {
                this.ads = data.data;
            }).catch(() => { });
        }
    }
}
</script>