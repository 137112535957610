<template>
    <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/">WELCOME TO RC DIRECTORY CMS</a>
        <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu" id="sidebarMenuTogglerBtn" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="col-12 d-md-none"></div>
        <div class="navbar-nav">
            <div class="nav-item text-nowrap">
                <button type="button" @click.prevent="logOut" class="nav-link bg-dark text-white px-3">Sign out</button>
            </div>
        </div>
    </header>

    <div class="container-fluid">
        <div class="row">
            <nav id="sidebarMenu" class="col-md-2 col-lg-2 d-md-block bg-pr sidebar collapse">
                <div class="position-sticky pt-3 sidebar-sticky">
                    <ul class="nav flex-column p-2">
                        <li class="nav-item">
                            <router-link :to="{ name: 'home', query: { sidebar: 1 } }" class="nav-link text-dark" aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">speed</span>
                                <span class="ml-1">Dashboard</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <button
                                class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link text-dark"
                                data-bs-toggle="collapse" data-bs-target="#sponsors" aria-expanded="false">
                                <span class="align-text-bottom material-symbols-outlined">demography</span>
                                <span class="mr-2 ml-2">Sponsors</span>
                                <span class="material-symbols-outlined">expand_more</span>
                            </button>
                            <div class="collapse mx-auto" id="sponsors">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><router-link :to="{ name: `sponsors.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                            aria-current="page">
                                            All Sponsors
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `sponsors.approved.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Approved Sponsors
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `sponsors.unapproved.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Unapproved Sponsors
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `sponsor-levels.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                            aria-current="page">
                                            Sponsor Levels
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `subscribers.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">notifications</span>
                                Subscriber
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <button
                                class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link text-dark"
                                data-bs-toggle="collapse" data-bs-target="#resources" aria-expanded="false">
                                <span class="align-text-bottom material-symbols-outlined">draft_orders</span>
                                <span class="mr-2 ml-2">Resources</span>
                                <span class="material-symbols-outlined">expand_more</span>
                            </button>
                            <div class="collapse mx-auto" id="resources">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><router-link :to="{ name: `resources.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                            aria-current="page">
                                            All Resources
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `resources.enabled.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Enabled Resources
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `resources.disabled.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Disabled Resources
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `administrators.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">manage_accounts</span>
                                Administrators
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `sale-representatives.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">monitoring</span>
                                Sales Representatives
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <button
                                class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link text-dark"
                                data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">
                                <span class="align-text-bottom material-symbols-outlined">box</span>
                                <span>Product Categories</span>
                                <span class="material-symbols-outlined">expand_more</span>
                            </button>
                            <div class="collapse mx-auto" id="orders-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><router-link :to="{ name: `product-categories.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Product Categories
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `product-subcategories.index`, query: { sidebar: 1 } }"
                                            class="nav-link text-dark" aria-current="page">
                                            Product Subcategories
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `products.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">inventory_2</span>
                                Products
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `brands.index`, query: { sidebar: 1 } }" class="nav-link text-dark" aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">sell</span>
                                Brands
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `industries.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">factory</span>
                                Industries
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: `newsletters.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                aria-current="page">
                                <span class="align-text-bottom material-symbols-outlined">mail</span>
                                Newsletters
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <button
                                class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link text-dark"
                                data-bs-toggle="collapse" data-bs-target="#settings-collapse" aria-expanded="false">
                                <span class="align-text-bottom material-symbols-outlined">settings</span>
                                <span class="mr-2 ml-2">Settings</span>
                                <span class="material-symbols-outlined">expand_more</span>
                            </button>
                            <div class="collapse mx-auto" id="settings-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><router-link :to="{ name: `ads.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                            aria-current="page">
                                            Ad Configuration
                                        </router-link>
                                    </li>
                                    <li><router-link :to="{ name: `scripts.index`, query: { sidebar: 1 } }" class="nav-link text-dark"
                                            aria-current="page">
                                            Header Injection
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <main class="col-md-10 ms-sm-auto col-lg-10 px-md-4">
                <router-view></router-view>
                <div class="toast-container position-static position-fixed top-0 end-0 p-3">
                    <div v-for="notification in notifications">
                        <toastr :notification="notification"></toastr>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
<script>
import Toastr from './Toast.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('notifications');
import jsCookie from 'js-cookie';

export default {
    name: "AdminLayout",

    computed: {

        ...mapState({
            notifications: state => state.items,
        }),
    },

    methods: {

        logOut() {
            if (!window.confirm('Are you ready to log out?')) return;

            jsCookie.remove('__LOGIN__');
            window.location.reload();
        },
    },

    components: {
        'toastr': Toastr,
    },

}
</script>
<style scoped>
.bg-pr {
    background-color: #FC8003;
}

.nav-item {
    background-color: #e6b07b5e !important;
    border: solid 2px #FC8003;
    margin-bottom: 2px;
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
        height: 90vh;
        overflow: auto;
        /* margin-bottom: 20rem; */
    }
}
</style>