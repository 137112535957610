<template>
    <div class="card mt-3">
        <div class="card-header">
            {{ $route.params.productid ? `Edit` : `Add` }} Product
        </div>
        <div class="card-body">
            <form @submit.prevent="saveProduct" id="product-form" class="row">
                <div class="col-md-4 col-12 p-1">
                    <label for="form-name" class="form-label">Name</label>
                    <input type="text" id="form-name" required
                        v-model.trim="product.name"
                        class="form-control form-control-sm">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-price" class="form-label">Price</label>
                    <input type="number" id="form-price" step="0.01"
                        v-model.trim="product.price"
                        class="form-control form-control-sm">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-qty" class="form-label">Qty</label>
                    <input type="number" id="form-qty" step="0.01"
                        v-model.trim="product.quantity"
                        class="form-control form-control-sm">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-category" class="form-label">Category</label>
                    <select v-model="product.category_id" required id="form-category" class="form-select form-select-sm">
                        <option v-for="category in categories" :value="category.category_id">{{  category.name }}</option>
                    </select>
                </div>
                <div class="col-12 p-1">
                    <label for="form-description" class="form-label">Description</label>
                    <textarea v-model.trim="product.description" id="form-description" 
                        cols="30" rows="10" class="form-control"></textarea>
                </div>
                <div class="col-md-4 col-12">
                    <label for="form-cover-image">Cover Image</label>
                    <input type="file" ref="coverImage" accept="image/*" 
                        id="form-cover-image" class="form-control">
                </div>
                <div class="col-md-6 col-12" v-if="$route.params.productid">
                    <div class="mx-auto overflow-hidden" style="height:200px; width:200px;">
                        <img style="height:200px; width:auto;" :src="product.product_img" alt="">
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 text-end">
                    <button @click.prevent="$router.go(-1)" class="btn btn-link mr-4">Back</button>
                    <button type="submit" form="product-form" class="btn btn-sm btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductsForm",

    data() {

        return {
            product: {
                name: ``,
                category_id: null,
                description: ``,
                quantity: 0,
                price: 0,
            },
            categories: [],
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchProducts();
        await this.fetchCategories();
        this.$root.loading = false;
    },

    methods: {

        fetchProducts() {

            return window.axios.get(`products/${this.$route.params.productid}/view`).then(({ data }) => {
                this.product = data.data;
            }).catch(() => { });
        },

        fetchCategories() {

            return window.axios.get(`products/categories`).then(({ data }) => {
                this.categories = data.data;
            }).catch(() => { });
        },

        saveProduct() {
            this.$root.loading = true;
            const formData = new FormData();
            formData.append('user_id', this.$route.params.slug);
            formData.append('name', this.product.name);
            formData.append('description', this.product.description);
            formData.append('category_id', this.product.category_id);
            formData.append('quantity', this.product.quantity || 0);
            formData.append('price', this.product.price || 0);
            if(this.$refs.coverImage.files.length) {
                formData.append('image', this.$refs.coverImage.files[0]);
            }
            if(this.product.id) {
                formData.append('id', this.product.id);
            }

            window.axios.post(`products`, formData).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$router.go(-1);
            }).catch(() => { this.$root.loading = false; });
        },
    }
}
</script>