<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Subscribers</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchSubscribers(`users?search=${filter.search}&package=p1`).then(() => {
                            $root.loading = false;
                        })
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-1">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Company Name</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Package</th>
                                <th>Account Created</th>
                                <th>Approval Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="subscriber, key in subscribers.data">
                                <td>{{ (key + 1) * subscribers.current_page }}</td>
                                <td>{{ subscriber.company_name }}</td>
                                <td>{{ `${subscriber.first_name} ${subscriber.last_name}` }}</td>
                                <td>{{ subscriber.email }}</td>
                                <td>{{ subscriber.package }}</td>
                                <td>{{ $root.formatDate(subscriber.created_at) }}</td>
                                <td>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            v-model="subscriber.status"
                                            :value="true"
                                            id="flexSwitchCheckChecked">
                                    </div>
                                </td>
                                <td>
                                    <button type="button" @click.prevent="fetchDetails(subscriber.uid)"
                                        class="btn btn-xs btn-primary mr-1">View</button>
                                    <router-link :to="{ name: `sponsors.edit`, params: { id: subscriber.uid }}"
                                        class="btn btn-sm btn-link text-dark mr-1">Edit</router-link>
                                    <button type="button" 
                                        @click.prevent="deleteUser(subscriber.uid)" 
                                        class="btn btn-xs btn-danger">
                                        Delete
                                    </button>
                                    <subscriber-view
                                        :subscriber="subscriber"
                                        :userDetails="userDetails"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in subscribers.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchSubscribers(`${link.url}&search=${filter.search}&package=p1`)" v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SubscriberView from './View.vue'
export default {
    name: "SubscribersIndex",

    data() {

        return {
            subscribers: {
                data: [],
                links: [],
                current_page: 1,
            },
            userDetails: {
                products: [],
                resources: [],
                user: {}
            },
            filter: {
                search: ``
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchSubscribers();
        this.$root.loading = false;
    },

    methods: {

        fetchSubscribers(url = `users?package=p1`) {

            return window.axios.get(url).then(({ data }) => {
                let subscribers = data.data;
                let fsubscribers = {
                    ...subscribers,
                    data: subscribers.data.map(subscriber => ({ 
                        ...subscriber, 
                        status: subscriber.user_status == `approved` ? true : false
                    }))
                }
                this.subscribers = fsubscribers;
            }).catch(() => { });
        },

        fetchDetails(uid) {
            this.$root.loading = true;
            window.axios.get(`users/${uid}`).then(({ data }) => {
                this.userDetails = data.data;
                this.$root.loading = false;
                setTimeout(() => {
                const modal = new window.bootstrap.Modal(document.getElementById(`view-sponsor-modal-${uid}`), {});
                modal.show();
            }, 100);
            }).catch(() => { this.$root.loading = false; });
        },

        deleteUser(uid) {
            if(!window.confirm(`Confirm delete user`)) return;

            this.$root.loading = true;
            window.axios.delete(`users/${uid}`).then(({ data }) => {
                this.fetchSubscribers();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },
    },
    components: {
        'subscriber-view': SubscriberView
    },
}
</script>