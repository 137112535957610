<template>
    <div class="modal fade" :id="`categoryFormModal${category.category_id}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Product Category</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form :id="`category-form-${category.category_id}`" @submit.prevent="saveCategory" class="row">
                        <div class="col-12">
                            <label :for="`name-${category.category_id}`" class="form-label">Name</label>
                            <input type="text" required v-model.trim="category.name" :id="`name-${category.category_id}`" 
                                class="form-control">
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-xs btn-secondary" 
                        data-bs-dismiss="modal">Close</button>
                    <button :form="`category-form-${category.category_id}`" 
                        type="submit" class="btn btn-xs btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductCategoryForm",

    props: {
        category: {
            type: Object,
            required: true,
        },
    },

    methods: {

        saveCategory() {
            this.$root.loading = true;
            window.axios.post(`product-categories`, this.category).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$emit(`refresh`);
                this.$root.closeModal(`categoryFormModal${this.category.category_id}`);
            }).catch(() => {});
        }
    }
}
</script>