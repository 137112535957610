<template>
    <div class="card mt-3">
            <div class="card-header">
                <h5 class="h5">Newsletters</h5>
                <p class="text-muted small">Newsletter list.</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-end">
                        <router-link :to="{ name: `newsletters.form` }"
                            class="btn btn-xs btn-primary">Add Newsletter</router-link>
                    </div>
                    <div class="table-responsive mt-1">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Subject</th>
                                    <th>Scheduled On</th>
                                    <th>Category</th>
                                    <th>Created On</th>
                                    <th>Sent</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="newsletter,idx in newsletters.data">
                                    <td>{{ idx+1 }}</td>
                                    <td>{{ newsletter.subject }}</td>
                                    <td>{{ $root.formatDate(newsletter.scheduled_time) }}</td>
                                    <td>{{ newsletter.groups.reduce((str, grp) => `${str}, ${grp}`) }}</td>
                                    <td>{{ $root.formatDate(newsletter.created_at) }}</td>
                                    <td>
                                        {{ newsletter.sent ? `Sent` : `Not sent` }}
                                    </td>
                                    <td>
                                        <router-link class="btn btn-link"
                                            :to="{ name: `newsletters.edit`, 
                                                params: { id: newsletter.id } }">Edit</router-link>
                                        <button type="button" 
                                            @click.prevent="deleteNewsletter(newsletter.id)"
                                            class="btn btn-xs btn-danger">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item" v-for="link in newsletters.links">
                                    <button class="page-link" :class="{
                                        'disabled': link.url ? false : true,
                                        'bg-primary text-white': link.active
                                    }" @click.prevent="fetchNewsletters(`${link.url}&paginate=1`)" v-html="link.label"></button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "NewslettersIndex",

    data() {

        return {
            newsletters: {
                data: [],
                links: []
            },
        };
    },

    async mounted() {
        await this.fetchNewsletters();
    },

    methods: {

        fetchNewsletters(url=`newsletters`) {

            return window.axios.get(url).then(({ data }) => {
                this.newsletters = data;
            }).catch(() => {});
        },

        deleteNewsletter(id) {
            if(!window.confirm(`Confirm delete newsletter?`)) return;

            this.$root.loading = true;
            window.axios.delete(`newsletters/${id}`).then(({ data }) => {
                this.fetchNewsletters();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>