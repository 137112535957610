<template>
    <div class="modal fade" :id="`staff-form-modal-${staff.adminid}`" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5" id="staticBackdropLabel">Register Staff</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form :id="`staff-form-${staff.adminid}`" @submit.prevent="saveStaff" class="modal-body">
                    <div class="row">
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-first-name-${staff.adminid}`" class="form-label">First Name:*</label>
                            <input type="text" v-model.trim="staff.fname" :id="`grid-first-name-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-middle-name-${staff.adminid}`" class="form-label">Middle Name:*</label>
                            <input type="text" v-model.trim="staff.lname" :id="`grid-middle-name-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-last-name-${staff.adminid}`" class="form-label">Username:*</label>
                            <input type="text" v-model.trim="staff.uname" :id="`grid-last-name-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-email-${staff.adminid}`" class="form-label">Email:*</label>
                            <input type="text" v-model.trim="staff.email" :id="`grid-email-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-4 col-sm-12 p-1">
                            <label :for="`grid-email-${staff.adminid}`" class="form-label">Contact:*</label>
                            <input type="text" v-model.trim="staff.contactno" :id="`grid-email-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-6 col-sm-12 p-1">
                            <label :for="`grid-password-${staff.adminid}`" class="form-label">Password:*</label>
                            <input type="password" v-model.trim="staff.password" :id="`grid-password-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                        <div class="col-md-6 col-sm-12 p-1">
                            <label :for="`grid-cpassword-${staff.adminid}`" class="form-label">Confirm Password:*</label>
                            <input type="password" :id="`grid-cpassword-${staff.adminid}`"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </form>
                <div class="modal-footer">
                    <button type="button" class="btn btn-xs btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" :form="`staff-form-${staff.adminid}`" class="btn btn-xs btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StaffForm",

    emits: [ 'refresh', ],

    props: {
        staff: {
            type: Object,
            required: true,
            default: () => ({
                id: 0,
            })
        },
        roles: {
            type: Array,
            required: true,
        }
    },

    methods: {

        saveStaff() {
            this.$root.loading = true;
            window.axios.post(`administrators`, this.staff).then(({ data }) => {
                this.$root.loading = false; 
                this.$root.showNotification({ status: 1, message: data.message });
                this.$emit(`refresh`);
                this.$root.closeModal(`staff-form-modal-${this.staff.adminid}`);
            }).catch(() => { this.$root.loading = false; })
        }
    }
}
</script>