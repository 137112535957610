<template>
    <div class="card mt-4">
        <div class="card-header">
            <h1 class="h3">Add Sponsor/Subscriber</h1>
        </div>
        <div class="card-body">
            <div class="row" v-if="$route.params.id">
                <div class="col-md-4 col-12 overflow-hidden" style="width: 200px; height: 200px;">
                    <img style="height: 200px;" :src="sponsor.company_image" alt="logo">
                </div>
            </div>
            <form id="form-sponsor" @submit.prevent="saveSponsor" class="row">
                <div class="col-md-4 p-1">
                    <label for="form-first-name" class="form-label">First Name*</label>
                    <input type="text" v-model.trim="sponsor.first_name" required id="form-first-name" class="form-control">
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-last-name" class="form-label">Last Name*</label>
                    <input type="text" v-model.trim="sponsor.last_name" required id="form-last-name" class="form-control">
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-job-function" class="form-label">Job Function</label>
                    <input type="text" v-model.trim="sponsor.jobfunction" id="form-job-function" class="form-control">
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-email" class="form-label">Email*</label>
                    <input type="email" required v-model.trim="sponsor.email" id="form-email" class="form-control">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-status" class="form-label">Approval Status</label>
                    <select v-model="sponsor.user_status" name="" id="" class="form-select">
                        <option value="approved">Approved</option>
                        <option value="not approved">Not Approved</option>
                    </select>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-sponsor-level" class="form-label">Sponsor Level</label>
                    <select v-model="sponsor.sponsor_level_id" id="form-sponsor-level" class="form-select">
                        <option value="">select level</option>
                        <option v-for="level in sponsorLevels" :value="level.id">{{ level.name }}</option>
                    </select>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-ai" class="form-label">AI</label>
                    <select v-model="sponsor.active_ai" id="form-ai" class="form-select">
                        <option :value="1">Active</option>
                        <option :value="0">Not Active</option>
                    </select>
                </div>
                <div class="col-12 p-1 my-1">
                    <h1 class="h5">Expiry Dates Packages</h1>
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-start-date" class="form-label">Start Package Date</label>
                    <input type="date" v-model.trim="sponsor.start_package" 
                        id="form-start-date" class="form-control">
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-expiry-date" class="form-label">Expiry Package Date</label>
                    <input type="date" v-model.trim="sponsor.expire_package" 
                        id="form-expiry-date" class="form-control">
                </div>
                <div class="col-12 p-1 my-1">
                    <h1 class="h5">Company details</h1>
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-company-name" class="form-label">Company Name</label>
                    <input type="text" v-model.trim="sponsor.company_name" id="form-company-name" class="form-control">
                </div>
                
                <div class="col-md-4 p-1">
                    <label for="form-company-website" class="form-label">Company Website</label>
                    <input type="text" v-model.trim="sponsor.company_website" id="form-company-website" class="form-control">
                </div>
                <div class="col-md-4 p-1">
                    <label for="form-company-logo" class="form-label">Logo</label>
                    <input type="file" :required="!$route.params.id" ref="companylogo" accept="image/*"
                        id="form-company-logo" class="form-control">
                        <a :href="sponsor.company_image" target="_blank" class="text-xs btn-link">Logo</a>
                </div>
                <div class="col-12 p-1">
                    <label for="form-company-description" class="form-label">Company Description</label>
                    <textarea v-model.trim="sponsor.company_description" id="form-company-description" cols="30" rows="2" class="form-control"></textarea>
                </div>
                <div class="col-12 p-1">
                    <h1 class="h5">Contacts</h1>
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-work-phone" class="form-label">Work phone No</label>
                    <input type="text" v-model.trim="sponsor.work" id="form-work-phone" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-phone" class="form-label">Phone No</label>
                    <input type="text" v-model.trim="sponsor.cell" id="form-phone" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-address" class="form-label">Address</label>
                    <input type="text" v-model.trim="sponsor.address" id="form-address" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-zip-postal" class="form-label">Zip Postal</label>
                    <input type="text" v-model.trim="sponsor.zip_postal" id="form-zip-postal" class="form-control">
                </div>
                <div class="col-12 p-1 my-1">
                    <h1 class="h5">Socials</h1>
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-facebook" class="form-label">Facebook</label>
                    <input type="url" v-model.trim="sponsor.fb" id="form-facebook" class="form-control">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-linkedin" class="form-label">LinkedIn</label>
                    <input type="url" v-model.trim="sponsor.googleplus" id="form-linkedin" class="form-control">
                </div>
                <div class="col-md-4 col-12 p-1">
                    <label for="form-twitter" class="form-label">Twitter</label>
                    <input type="url" v-model.trim="sponsor.twitter" id="form-twitter" class="form-control">
                </div>
                <div class="col-12 p-1 my-1">
                    <h1 class="h5">Location</h1>
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-city" class="form-label">City</label>
                    <input type="text" v-model.trim="sponsor.city" id="form-city" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-region" class="form-label">Region</label>
                    <input type="text" v-model.trim="sponsor.region" id="form-region" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-province" class="form-label">Province</label>
                    <input type="text" v-model.trim="sponsor.state_province" id="form-province" class="form-control">
                </div>
                <div class="col-md-6 col-12 p-1">
                    <label for="form-country" class="form-label">Country</label>
                    <input type="text" v-model.trim="sponsor.country" id="form-country" class="form-control">
                </div>
                
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 text-end">
                    <button @click.prevent="$router.back()" class="btn btn-link mr-4">Back</button>
                    <button type="submit" form="form-sponsor" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SponsorForm",

    data() {

        return {
            sponsor: {
                first_name: ``,
                last_name: ``,
                email: ``,
                jobfunction: ``,
                sponsor_level_id: ``,
                user_status: `approved`,
                company_name: ``,
                company_website: ``,
                company_description: ``,
                fb: ``,
                twitter: ``,
                googleplus: ``,
                cell: ``,
                work: ``,
                address: ``,
                region: ``,
                city: ``,
                state_province: ``,
                zip_postal: ``,
                country: ``,
                active_ai: false,
                start_package: null,
                expire_package: null,                
            },
            sponsorLevels: [],
        };
    },

    async mounted(){
        this.$root.loading = true;
        await this.fetchSponsor();
        await this.fetchSponsorLevels();
        this.$root.loading = false;
    },

    methods: {

        fetchSponsorLevels() {

            return window.axios.get(`sponsor-levels/list`).then(({ data }) => {
                this.sponsorLevels = data.data;
            }).catch(() => { });
        },

        fetchSponsor() {
            if(!this.$route.params.id) return;

            return window.axios.get(`users/${this.$route.params.id}`).then(({ data }) => {
                this.sponsor = data.data.user;
            }).catch(() => {});
        },

        saveSponsor() {
            const formData = new FormData();
            formData.append('first_name', this.sponsor.first_name);
            formData.append('last_name', this.sponsor.last_name);
            formData.append('jobfunction', this.sponsor.jobfunction);
            formData.append('sponsor_level_id', this.sponsor.sponsor_level_id);
            formData.append('user_status', this.sponsor.user_status);
            formData.append('email', this.sponsor.email);
            formData.append('company_name', this.sponsor.company_name);
            formData.append('company_website', this.sponsor.company_website);
            formData.append('company_description', this.sponsor.company_description);
            formData.append('fb', this.sponsor.fb);
            formData.append('twitter', this.sponsor.twitter);
            formData.append('googleplus', this.sponsor.googleplus);
            formData.append('cell', this.sponsor.cell);
            formData.append('work', this.sponsor.work);
            formData.append('address', this.sponsor.address);
            formData.append('region', this.sponsor.region);
            formData.append('city', this.sponsor.city);
            formData.append('state_province', this.sponsor.state_province);
            formData.append('zip_postal', this.sponsor.zip_postal);
            formData.append('country', this.sponsor.country);
            formData.append('active_ai', this.sponsor.active_ai);
            if(this.sponsor.start_package) {
                formData.append('start_package', this.sponsor.start_package);
            }
            if(this.sponsor.expire_package) {
                formData.append('expire_package', this.sponsor.expire_package);
            }
            if(this.$route.params.id) {
                formData.append('id', this.sponsor.id);
            }
            if(this.$refs.companylogo.files.length) {
                formData.append('image', this.$refs.companylogo.files[0]);
            }
            
            this.$root.loading = true;
            window.axios.post(`users`, formData).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$router.back();
            }).catch(() => { this.$root.loading = false; });
        },
    },
}
</script>