<template>
    <div class="row">
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Name
            </h5>
            <h6 class="h6">
                {{ `${user.first_name} ${user.last_name}` }}
            </h6>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Company
            </h5>
            <h6 class="h6">
                {{ user.company_name }}
            </h6>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Email
            </h5>
            <h6 class="h6">
                {{ user.email }}
            </h6>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Approval Status
            </h5>
            <h6 class="h6">
                {{ user.user_status }}
            </h6>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Address
            </h5>
            <p>
                {{ user.address }}
            </p>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Zip Postal
            </h5>
            <p>
                {{ user.zip_postal }}
            </p>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Country
            </h5>
            <h6 class="h6">
                {{ user.country }}
            </h6>
        </div>
        <div class="col-md-4 col-12 p-2">
            <h5 class="h5">
                Region
            </h5>
            <h6 class="h6">
                {{ user.region || `-` }}
            </h6>
        </div>
    </div>
</template>
<script>
export default {
    name: "CompanyTab",

    props: {
        user: {
            type: Object,
        },
    },
}
</script>