<template>
    <div class="card mt-4">
        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h1 class="h5">
                        {{ resource.resource_topic }}
                    </h1>
                </div>
                <div class="col text-end">
                    <router-link v-if="resource.id"
                        :to="{ 
                            name: `sponsors.resources.edit`, 
                            params: { 
                                resourceId: resource.resources_id,
                                slug: resource.user_id,
                            } 
                        }" 
                    class="btn btn-sm btn-info mr-4">Edit</router-link>
                    <button 
                        type="button" data-bs-toggle="modal"
                        :data-bs-target="`#resource-form-modal-${resource.id}`"
                        class="btn btn-sm btn-warning text-white mr-2">
                        Approve
                    </button>
                    <button type="button" @click.prevent="deleteResource(resource.id)" class="btn btn-sm btn-danger text-white">
                        Delete
                    </button>
                </div>
            </div>
            <ApprovalForm :resource="resource" />
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-5 col-11 mx-auto overflow-hidden p-1" style="height:400px;">
                    <img :src="resource.image_path" style="width:400px; height:auto;" alt="">
                </div>
                <div class="col-12 mt-4 mb-4">
                    <div v-html="resource.description"></div>
                </div>
                <div class="col-md-4 col-12">
                    <a :href="resource.resource_path" target="_blank" class="btn btn-sm btn-primary">
                        View Resource 
                    </a>
                    ({{ resource.resource_path }})
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" @click.prevent="$router.go(-1)"
                        class="btn btn-sm btn-secondary text-white">
                        Back
                </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApprovalForm from './Approval.vue';
export default {
    name: "ResourceDetails",

    data() {

        return {
            resource: {},
        };
    },

    async mounted() {

        this.$root.loading = true;
        await this.fetchResourceDetails();
        this.$root.loading = false;
    },

    methods: {

        fetchResourceDetails() {

            return window.axios.get(`resources/${this.$route.params.resourceid}`).then(({ data }) => {
                console.log(data);
                this.resource = data;
            }).catch(() => { });
        },

        deleteResource(id) {
            if(!window.confirm(`Confirm delete resource`)) return;

            this.$root.loading = true;
            window.axios.delete(`resources/sponsors/${id}`).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$router.go(-1);
            }).catch(() => { this.$root.loading = false; });
        },
    },
    components: {
        ApprovalForm,
    }
}
</script>