<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table mt-4">
                    <thead class="bg-light">
                        <tr>
                            <th>Resource</th>
                            <th>Category</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="resource in resources">
                            <td>{{ resource.resource_topic }}</td>
                            <td>{{ resource.category?.industryname }}</td>
                            <td>{{ resource.status }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ResourcesTab",

    props: {
        resources: {
            type: Array,
            required: true,
            default: () => ([]),
        },
    },
}
</script>