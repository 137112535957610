<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Brands</h5>
            <p class="text-muted small">List of brands.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" data-bs-toggle="modal" :data-bs-target="`#brandFormModal${addBrand.brandid}`"
                        class="btn btn-xs btn-primary">Add Brand</button>
                </div>
                <brand-form :brand="addBrand" @refresh="fetchBrands"></brand-form>
                <div class="col-md-4 col-12 p-1">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchBrands(`brands?search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="brand, idx in brands.data">
                                <td>{{ idx + 1 }}</td>
                                <td>{{ brand.brandname }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#brandFormModal${brand.brandid}`"
                                        class="btn btn-xs btn-primary mr-1">Edit</button>
                                    <button type="button" @click.prevent="deleteBrand(brand.brandid)"
                                        class="btn btn-xs btn-danger">Delete</button>
                                    <brand-form :brand="brand" @refresh="fetchBrands"></brand-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in brands.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchBrands(link.url)" v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BrandForm from './form.vue';
export default {
    name: "BrandsIndex",

    data() {

        return {
            brands: {
                data: []
            },
            addBrand: {
                brandid: 0,
                brandname: ``,
            },
            filter: {
                search: ``,
                limit: 20,
            },
        };
    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchBrands();
        this.$root.loading = false;
    },

    methods: {

        fetchBrands(url = `brands`) {

            return window.axios.get(url).then(({ data }) => {
                this.brands = data.data;
            }).catch(() => { });
        },

        deleteBrand(id) {
            if(!window.confirm(`Confirm Delete brand`)) return;

            this.$root.loading = true;
            window.axios.delete(`brands/${id}`).then(({ data }) => {
                this.fetchBrands();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },
    },

    components: {
        'brand-form': BrandForm,
    },
}
</script>