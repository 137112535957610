<template>
    <div class="container mt-6">
        <div class="row">
            <div class="col-12 p-1">
                <h1 class="h2">Welcome to RC Directory CMS!</h1>
                <h3 class="h5">These stats are for the month</h3>
            </div>
            <div class="col-md-4 p-3">
                <div class="card bg-graph p-4 text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Products</h2>
                            <h4 class="h4">
                                <span class="align-text-bottom material-symbols-outlined">notifications</span>
                                {{ statistics.totals.products }}</h4>
                        </div>
                        <div class="col-12">
                            <Line :style="{ height: `200px`, width: `300px` }" :data="chartDataProducts" :options="chartOptions"></Line>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-4 p-3">
                <div class="card bg-graph p-4 text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Resources</h2>
                            <h4 class="h4">
                                <span class="align-text-bottom material-symbols-outlined">send</span>
                                {{ statistics.totals.resources }}</h4>
                        </div>
                        <div class="col-12">
                            <Line :style="{ height: `200px`, width: `300px` }" :data="chartDataResources" :options="chartOptions"></Line>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-4 p-3">
                <div class="card bg-graph p-4 text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Sponsors</h2>
                            <h4 class="h4">
                                <span class="align-text-bottom material-symbols-outlined">electric_rickshaw</span>
                                {{ statistics.totals.sponsors }}</h4>
                        </div>
                        <div class="col-12">
                            <Line :style="{ height: `200px`, width: `300px` }" 
                                :data="chartDataSponsors" :options="chartOptions"></Line>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-4 p-3">
                <div class="card bg-graph p-4 text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Subscribers</h2>
                            <h4 class="h4">
                                <span class="align-text-bottom material-symbols-outlined">notifications</span>
                                {{ statistics.totals.subscribers }}</h4>
                        </div>
                        <div class="col-12">
                            <Line :style="{ height: `200px`, width: `300px` }" 
                                :data="chartDataSubscribers" :options="chartOptions"></Line>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-3">
                <div class="card p-4 bg-graph text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Views</h2>
                            <h4 class="h4">
                                <span class="align-text-bottom material-symbols-outlined">notifications</span>
                                {{ statistics.totals.views }}</h4>
                        </div>
                        <div class="col-12">
                                <Line :style="{ height: `200px`, width: `300px` }" 
                                :data="chartData" :options="chartOptions"></Line>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-3">
                <div class="card p-4 bg-graph text-start">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="h2">Downloads</h2>
                            <h4 class="h4"><span class="align-text-bottom material-symbols-outlined">download</span>
                                {{ statistics.totals.downloads }}</h4>
                        </div>
                        <div class="col-12">
                                <Line :style="{ height: `200px`, width: `300px` }" 
                                :data="chartDataDownloads" :options="chartOptions"></Line>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip, BarElement
} from "chart.js";

import { Line } from "vue-chartjs";
import moment from "moment";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    BarElement
);

export default {
    name: "Dashboard",

    data() {

        return {
            statistics: {
                resources: [],
                subscribers: [],
                sponsors: [],
                products: [],
                views: [],
                downloads: [],
                totals: {
                    views: 0,
                    downloads: 0,
                    resources: 0,
                    sponsors: 0,
                    products: 0,
                }
            },

        }
    },

    computed: {

        chartData() {
            return {
                labels: this.statistics.views.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.views.map(stat => stat.views)
                    }
                ]
            };
        },
        chartDataProducts() {
            return {
                labels: this.statistics.products.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.products.map(stat => stat.total)
                    }
                ]
            };
        },
        chartDataResources() {
            return {
                labels: this.statistics.resources.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.resources.map(stat => stat.total)
                    }
                ]
            };
        },
        chartDataSubscribers() {
            return {
                labels: this.statistics.subscribers.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.subscribers.map(stat => stat.total)
                    }
                ]
            };
        },
        chartDataSponsors() {
            return {
                labels: this.statistics.sponsors.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.sponsors.map(stat => stat.total)
                    }
                ]
            };
        },
        chartDataDownloads() {
            return {
                labels: this.statistics.downloads.map(stat => moment().month(stat.month).format(`MMM`)),
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#f87979',
                        lineTension: 1,
                        data: this.statistics.downloads.map(stat => stat.total)
                    }
                ]
            };
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
            };
        }

    },

    async mounted() {
        this.$root.loading = true;
        await this.fetchStatistics();
        this.$root.loading = false;
    },

    methods: {

        fetchStatistics(url = `statistics`) {

            return window.axios.get(`${url}`).then(({ data }) => {
                this.statistics = data;

            }).catch(() => { });
        },

    },
    components: { Line },
}
</script>
<style scoped>
.bg-graph {
    background-color: #faf3e1;
}
</style>