<template>
<div class="modal fade" :id="`sponsor-level-${sponsor.uid}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Change Level</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form :id="`form-sponsor-${sponsor.uid}`" @submit.prevent="saveChangeLevel" class="row">
            <div class="col-12">
                <label :for="`form-level-${sponsor.uid}`">Levels</label>
                <select v-model="sponsor.sponsor_level_id" :id="`form-level-${sponsor.uid}`" class="form-select form-select-sm">
                    <option v-for="level in levels" :value="level.id">{{ level.name }}</option>
                </select>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" :form="`form-sponsor-${sponsor.uid}`" 
            class="btn btn-sm btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name: "ChangeLevelModal",

    props: {

        sponsor: {
            type: Object,
            required: true,
        },
        levels: {
            type: Array,
            required: true,
        },
    },

    methods: {

        saveChangeLevel() {
            this.$root.loading = true;
            const level = { level: this.sponsor.sponsor_level_id};
            window.axios.post(`users/change-level/${this.sponsor.uid}`, level).then(({ data }) => {
                this.$emit(`refresh`);
                this.$root.closeModal(`sponsor-level-${this.sponsor.uid}`);
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                
            }).catch(() => { this.$root.loading = false; });
        },
    }
}
</script>