import SponsorsIndex from './Index.vue';
import SponsorsForm from './Form.vue';
import SponsorsUnapprovedIndex from './Unapproved.vue';
import SponsorsApprovedIndex from './Approved.vue';
import SponsorResourcesIndex from './resources/Index.vue';
import SponsorResourcesForm from './resources/Form.vue';
import SponsorResourcesView from './resources/View.vue';

import SponsorProductsIndex from './products/Index.vue';
import SponsorProductForm from './products/Form.vue';

export const routes = [
    {
        path: 'sponsors',
        component: SponsorsIndex,
        name: 'sponsors.index',
    },
    {
        path: 'sponsors/form',
        component: SponsorsForm,
        name: 'sponsors.form',
    },
    {
        path: 'sponsors/form/:id',
        component: SponsorsForm,
        name: 'sponsors.edit',
    },
    {
        path: 'sponsors/approved',
        component: SponsorsApprovedIndex,
        name: 'sponsors.approved.index',
    },
    {
        path: 'sponsors/unapproved',
        component: SponsorsUnapprovedIndex,
        name: 'sponsors.unapproved.index',
    },
    {
        path: `sponsors/resources/:slug`,
        component: SponsorResourcesIndex,
        name: 'sponsors.resources.index'
    },
    {
        path: `sponsors/resources/form/:slug`,
        component: SponsorResourcesForm,
        name: 'sponsors.resources.form'
    },
    {
        path: `sponsors/resources/form/:slug/edit/:resourceId`,
        component: SponsorResourcesForm,
        name: 'sponsors.resources.edit'
    },
    {
        path: `sponsors/resources/view/:resourceid`,
        component: SponsorResourcesView,
        name: 'sponsors.resources.view'
    },
    {
        path: `sponsors/products/:slug`,
        component: SponsorProductsIndex,
        name: 'sponsors.products.index'
    },
    {
        path: `sponsors/products/:slug/form`,
        component: SponsorProductForm,
        name: 'sponsors.products.form'
    },
    {
        path: `sponsors/products/:slug/form/:productid`,
        component: SponsorProductForm,
        name: 'sponsors.products.edit'
    },
];
