<template>
    <div class="modal fade" :id="`resource-form-modal-${resource.id}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5" id="exampleModalLabel">{{ resource.resource_topic }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form :id="`form-resource-${resource.id}`" @submit.prevent="saveStatus" class="col-12">
                        <label :for="`resource-status-${resource.id}`">Status</label>
                        <select v-model="resource.status" :id="`resource-status-${resource.id}`" class="form-select">
                            <option value="enabled">enabled</option>
                            <option value="disable">disable</option>
                        </select>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" :form="`form-resource-${resource.id}`" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "EditResource",

    props: {
        resource: {
            type: Object,
            required: true,
        },
    },

    methods: {

        saveStatus() {
            this.$root.loading = true;
            window.axios.post(`resources/${this.resource.resources_id}`, { status: this.resource.status }).then(({ data }) => {
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
                this.$emit(`refresh`);
                this.$root.closeModal(`resource-form-modal-${this.resource.id}`);
            }).catch(() => { this.$root.loading = false; });
        }
    }
}
</script>