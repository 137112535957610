<template>
    <div class="card mt-3">
        <div class="card-header">
            <h5 class="h5">Administrators</h5>
            <p class="text-muted small">Administrators are able to log in here.</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-end">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#staff-form-modal-0"
                        class="btn btn-xs btn-primary">Add Admin</button>
                </div>
                <staff-form @refresh="() => {
                    fetchStaff();
                    addStaff = {
                        fname: ``,
                        uname: ``,
                        lname: ``,
                        email: ``,
                        contactno: ``,
                        adminid: 0,
                        password: ``,
                    };
                }" :staff="addStaff" :roles="roles"></staff-form>
                <div class="col-md-4 col-12">
                    <form @submit.prevent="() => {
                        $root.loading = true;
                        fetchStaff(`administrators?paginate=1&search=${filter.search}`).then(() => {
                            $root.loading = false;
                        });
                    }" class="input-group">
                        <input type="text" v-model.trim="filter.search" class="form-control">
                        <button class="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-sm small">
                        <thead class="bg-secondary text-white">
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Contact No</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="staff, key in staffs.data.data">
                                <td>{{ (key + 1) * staffs.data.current_page }}</td>
                                <td>{{ `${staff.fname} ${staff.lname}` }}</td>
                                <td>{{ staff.uname }}</td>
                                <td>{{ staff.email }}</td>
                                <td>{{ staff.contactno }}</td>
                                <td>
                                    <button type="button" data-bs-toggle="modal"
                                        :data-bs-target="`#staff-form-modal-${staff.adminid}`"
                                        class="btn btn-xs btn-primary mr-1">Edit</button>
                                    <button type="button" @click.prevent="deleteStaff(staff.adminid)"
                                        class="btn btn-xs btn-danger">
                                        Delete
                                    </button>
                                </td>
                                <staff-form @refresh="() => {
                                    fetchStaff()
                                }" :staff="staff"></staff-form>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item" v-for="link in staffs.data.links">
                                <button class="page-link" :class="{
                                    'disabled': link.url ? false : true,
                                    'bg-primary text-white': link.active
                                }" @click.prevent="fetchStaff(link.url)" v-html="link.label"></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StaffForm from './Form.vue';
export default {
    name: "StaffIndex",

    data: () => ({
        staffs: {
            data: { data: [] },
            links: [],
        },
        addStaff: {
            fname: ``,
            uname: ``,
            lname: ``,
            email: ``,
            contactno: ``,
            adminid: 0,
            password: ``,
        },
        roles: [],
        filter: {
            search: ``,
        },
    }),

    async mounted() {
        this.$root.loading = true;
        await this.fetchStaff();
        this.$root.loading = false;
    },

    methods: {

        fetchStaff(url = `administrators?paginate=1`) {

            return window.axios.get(`${url}&paginate=1`).then(({ data }) => {
                console.log
                this.staffs = data;
            }).catch(() => { });
        },

        deleteStaff(id) {
            if(!window.confirm(`Confirm Delete Staff`)) return;
            this.$root.loading = true;
            window.axios.delete(`administrators/${id}`).then(({ data }) => {
                this.fetchStaff();
                this.$root.loading = false;
                this.$root.showNotification({ status: 1, message: data.message });
            }).catch(() => { this.$root.loading = false; });
        },

    },

    components: {
        'staff-form': StaffForm,
    },
}
</script>